<template >
  <!-- v-show="item.tipo_segmento === 1 || item.tipo_segmento === 2" -->
  <div
    class="grid col-12 font table-separate"
    style="margin-bottom: 10px; padding: 10px;"
    v-for="(item, index) in dados"
    :key="index"
  >
    <div class="grid justify-content-between align-items-center col-12" v-if="cabecalho">
      <div class="col-5 align-content-center">
        <img
          style="width: auto; height: 100px;"
          :src="base_url + '/storage/' + cabecalho.logo"
          alt=""
          class="img"
        />
      </div>

      <div
        style="margin: 0px !important; text-align: center;"
        v-html="cabecalho.texto_logo"
        class="cabecalho font-cabecalho mr-5 col-12"
      ></div>
    </div>


    <table class="col-12 mt-2 borda-externa" style="min-width: 1500px;" cellpadding=15  cellspacing=15 cellmargin="15">
      <thead class="cor-titulo">
        <tr align="center">
          <th colspan="3">APROVEITAMENTO DO ALUNO </th>
        </tr>
      </thead>

      <tbody class="borda-externa">
        <tr>
          <td class="">Aluno(a): {{ item.nome }}</td>
          <td class="">Data de Nascimento: {{ item.nascimento }}</td>
        </tr>

        <tr>
          <td class="">Unidade Escolar: {{ info.escola.nome }}</td>
          <td class="">CPF: {{ item.cpf }}</td>
        </tr>

        <tr>
          <td class="">
            Ano de Escolaridade: {{ item.serie_turma }}
            <!-- <span v-for="(serie, index) in series" :key="index">
              (){{ serie.nome }}</span
            > -->
          </td>
          <td class="">RG: {{ item.rg }}</td>
        </tr>

        <tr>
          <td class="">Turno: {{ item.turno_turma }}</td>
          <td class="borda-">Naturalidade: {{ item.naturalidade }}</td>
        </tr>

        <tr>
          <td class="borda-">Turma: {{ item.nome_turma }}</td>
          <td class="borda-">Mãe: {{ item.nome_mae }}</td>
        </tr>
        <tr>
          <td class="borda-">Ano Letivo: {{ item.ano_letivo }}</td>
          <td class="borda-">Pai: {{ item.nome_pai }}</td>
        </tr>
        <tr>
        </tr>
      </tbody>
    </table>

    <table class="col-12 borda-externa tabela" style="min-width: 1500px;" border="1">
      <thead>
        <tr align="center">
          <th colspan="19">INTERVALO DE DATAS: {{ dataInicio }} - {{ dataFim }}</th>
          <!-- <th colspan="19" v-else>INTERVALO DE DATAS: {{ item.dataInicio }} - {{ item.dataFim }}</th> -->
        </tr>
        <tr align="center">
          <th rowspan="2" class="bordas title-padding">AREA DE CONHECIMENTO</th>
          <th rowspan="2" class="bordas title-padding borda-right">
            COMPONENTES CURRICULARES
          </th>
          <td rowspan="2" class="bordas title-padding">Aulas Dadas:</td>
          <td rowspan="2" class="bordas title-padding borda-right">Frequência</td>
          <td rowspan="2" class="bordas title-padding">Aproveitamento</td>
        </tr>
      </thead>

      <tbody>
        <tr
          class=""
          align="center"
          v-for="(componente, index) in item.info_disciplina_por_area"
          :key="index"
        >
          <td class="borda-top-1px borda-right-1px">
            <div class="borda-top-1px" >
              {{ index }}
            </div>
          </td>

          <td class="borda-top-1px borda-right" >
            <div

              class="borda-top-1px linhas"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              {{ disciplina.nome }}
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.aulas_dadas">{{ disciplina.aulas_dadas }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.percentual">{{ disciplina.aulas_lancadas }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              {{ disciplina.percentual }} %
            </div>
          </td>


        </tr>

        <tr align="center" class="borda-top">

        </tr>


      </tbody>
    </table>
    <!--
    <table class="col-12 borda-externa" style="min-width: 1500px;" cellpadding=15  cellspacing=15 cellmargin="15">
      <tbody>
        <tr>
          <td colspan="3">Observação:
            <label v-if="item.resultado_final === 'apc' && item.numeroAta" >Aprovado pela ata {{ item.numeroAta }}</label> <br>
            {{ item.observacao_geral }}</td>
        </tr>

        <tr align="center" >
          <td style="padding-top: 30px;">___________________</td>
          <td style="padding-top: 30px;">___________________</td>
          <td style="padding-top: 30px;">___________________</td>
        </tr>
        <tr align="center">
          <td>Secretário (a)</td>
          <td>Diretor (a)</td>
          <td>Supervisor (a)</td>
        </tr>
      </tbody>
    </table> -->
  </div>

  <!-- <div
    class="grid col-12 font"
    style="margin-bottom: 10px; padding: 10px"
    v-for="(item, index) in dados"
    :key="index"
  >
    <table class="col-12 mt-2 borda-externa">
      <thead class="cor-titulo">
        <tr align="center">
          <th colspan="3">FICHA INDIVIDUAL - {{ item.segmento_escolar }}</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>Total de Dias Letivos: {{ item.total_dias_letivos }}</td>
          <td>Ano Letivo: {{ item.anoletivo }}</td>
        </tr>

        <tr>
          <td>Unidade Escolar: {{ item.escola_nome }}</td>
          <td>Endereço: {{ item.endereco.logradouro }}</td>
        </tr>

        <tr>
          <td>Aluno(a): {{ item.aluno_nome }}</td>
          <td>Data de Nascimento: {{ item.data_nascimento }}</td>
          <td>Naturalidade: {{ item.naturalidade }}</td>
        </tr>

        <tr>
          <td>Mãe: {{ item.mae }}</td>
          <td>Pai: {{ item.pai }}</td>
        </tr>

        <tr>
          <td>
            Ano de Escolaridade: {{ item.ano_de_escolaridade }}
            <span v-for="(serie, index) in series" :key="index">
              (){{ serie.nome }}</span
            >
          </td>
          <td>Turma: {{ item.turma }}</td>
          <td>Turno: {{ item.turno }}</td>
        </tr>
      </tbody>
    </table>

    <table class="col-12 borda-externa tabela">
      <thead>
        <tr align="center">
          <th rowspan="2" class="bordas title-padding borda-right">
            COMPONENTES CURRICULARES
          </th>
          <th colspan="3" class="bordas title-padding borda-right">
            1° periodo
          </th>
          <th colspan="3" class="bordas title-padding borda-right">
            2° periodo
          </th>
          <th colspan="3" class="bordas title-padding borda-right">
            3° periodo
          </th>
          <th colspan="3" class="bordas title-padding borda-right">
            4° periodo
          </th>
          <td rowspan="2" class="bordas title-padding">Nota Parc. Final</td>
          <td rowspan="2" class="bordas title-padding">Rec. Final</td>
          <td rowspan="2" class="bordas title-padding">Resul. Anual</td>
          <td rowspan="2" class="bordas title-padding">Total Aulas de Dadas</td>
          <td rowspan="2" class="bordas title-padding">Total de Frequência</td>
          <td rowspan="2" class="bordas title-padding">
            Cálculo Global de Aulas Dadas
          </td>
          <td rowspan="2" class="bordas title-padding">
            Cálculo Global de Frequência
          </td>
          <td rowspan="2" class="bordas title-padding">% frequencia</td>
          <td rowspan="2" class="bordas title-padding">Visto Supervisor</td>
        </tr>
        <tr align="center">
          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>

          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>

          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>

          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>
        </tr>
      </thead>

      <tbody>
        <tr
          align="center"
          v-for="(componente, index) in item.componentes_curriculares"
          :key="index"
        >
          <td class="bordas borda-right">
            {{ componente.nome_componente }}
          </td>

          <td class="bordas">
            {{ componente.p1_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p1_letivo.aulas_dadas }}
          </td>
          <td class="bordas borda-right">
            {{ componente.p1_letivo.presenca }}
          </td>

          <td class="bordas">
            {{ componente.p2_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p2_letivo.aulas_dadas }}
          </td>
          <td class="bordas borda-right">
            {{ componente.p2_letivo.presenca }}
          </td>

          <td class="bordas">
            {{ componente.p3_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p3_letivo.aulas_dadas }}
          </td>
          <td class="bordas borda-right">
            {{ componente.p3_letivo.presenca }}
          </td>

          <td class="bordas">
            {{ componente.p4_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p4_letivo.aulas_dadas }}
          </td>
          <td class="bordas borda-right">
            {{ componente.p4_letivo.presenca }}
          </td>

          <td class="bordas">{{ componente.nota_final }}</td>
          <td
            v-if="componente.calculo_geral.recuperacao_final == null"
            class="bordas"
          >
            -
          </td>
          <td v-else class="bordas">
            {{ componente.calculo_geral.recuperacao_final }}
          </td>
          <td class="bordas">{{ componente.calculo_geral.resultado_anual }}</td>
          <td class="bordas">
            {{ componente.calculo_geral.total_aulas_dadas }}
          </td>
          <td class="bordas">
            {{ componente.calculo_geral.total_frequencia }}
          </td>
          <td class="bordas">{{ item.calculo_global_aulas }}</td>
          <td class="bordas">{{ item.calculo_global_frequencia }}</td>
          <td class="bordas">
            {{ componente.calculo_geral.porcentagem_frequencia }}%
          </td>
          <td rowspan=""></td>
        </tr>

        <tr v-if="info.segmento_id.tipo === 1">
          <th colspan="17" class="bordas borda-top">
            Legenda: Promovido-(Pr), Retido-(Ret), Evadido-(Ev) ou
            Transferido-(Tr), Conteúdo Explorado-(CE).
          </th>
          <th
            class="bordas borda-top"
            colspan="5"
            style="text-transform: uppercase"
            v-if="item.resultado_final === 'reprovado'"
          >
            RESULTADO FINAL: Ret
          </th>
          <th
            class="bordas borda-top"
            colspan="5"
            style="text-transform: uppercase"
            v-if="item.resultado_final === 'aprovado'"
          >
            RESULTADO FINAL: Pr
          </th>
          <th
            class="bordas borda-top"
            colspan="5"
            style="text-transform: uppercase"
            v-if="item.resultado_final === 'evadido'"
          >
            RESULTADO FINAL: Ev
          </th>
          <th
            class="bordas borda-top"
            colspan="5"
            style="text-transform: uppercase"
            v-if="item.resultado_final === 'Transferido'"
          >
            RESULTADO FINAL: Tr
          </th>
        </tr>

        <tr v-if="info.segmento_id.tipo === 2" align="center" class="borda-top">
          <td colspan="2" class="bordas">PROGRESSÃO PARCIAL</td>
          <td colspan="3" class="bordas">Componentes Curriculares</td>
          <td colspan="3" class="bordas">Ano de Escolaridade</td>
          <td colspan="3" class="bordas">AULAS DADAS</td>
          <td colspan="3" class="bordas">Percentual Frequência</td>
          <td colspan="3" class="bordas">Ratificação Supervisor</td>
          <th
            class="bordas borda-bottom"
            rowspan="3"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
            v-if="item.resultado_final === 'reprovado'"
          >
            RESULTADO FINAL: Ret
          </th>
          <th
            class="bordas borda-bottom"
            rowspan="3"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
            v-if="item.resultado_final === 'aprovado'"
          >
            RESULTADO FINAL: Pr
          </th>
          <th
            class="bordas borda-bottom"
            rowspan="3"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
            v-if="item.resultado_final === 'evadido'"
          >
            RESULTADO FINAL: Ev
          </th>
          <th
            class="bordas borda-bottom"
            rowspan="3"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
            v-if="item.resultado_final === 'Transferido'"
          >
            RESULTADO FINAL: Tr
          </th>
          <th colspan="2" rowspan="3" class="borda-bottom">
            Percentual Global de frequência: <br />
            {{ item.controle_de_frequencia.percentual_frequencia }}%
          </th>
        </tr>

        <tr v-if="info.segmento_id.tipo === 2" align="center">
          <td colspan="2" rowspan="1" class="bordas">Dependencia(s)</td>
          <td colspan="3" class="bordas"></td>
          <td colspan="3" class="bordas"></td>
          <td colspan="3" class="bordas"></td>
          <td colspan="3" class="bordas"></td>
          <td colspan="3" class="bordas"></td>
        </tr>
        <tr v-if="info.segmento_id.tipo === 2" align="center">
          <td colspan="2" rowspan="1" class="bordas borda-bottom">
            Dependencia(s)
          </td>

          <td colspan="3" class="bordas borda-bottom"></td>
          <td colspan="3" class="bordas borda-bottom"></td>
          <td colspan="3" class="bordas borda-bottom"></td>
          <td colspan="3" class="bordas borda-bottom"></td>
          <td colspan="3" class="bordas borda-bottom"></td>
        </tr>
        <tr v-if="info.segmento_id.tipo === 2">
          <th colspan="22" class="bordas">
            Legenda: Promovido-(Pr), Retido-(Ret), Evadido-(Ev) ou
            Transferido-(Tr), Conteúdo Explorado-(CE).
          </th>
        </tr>
      </tbody>
    </table>

    <table class="col-12 borda-externa">
      <tbody>
        <tr>
          <td colspan="3">Observação: {{ item.observacao_geral }}</td>
        </tr>

        <tr align="right">
          <td colspan="3">
            Cardoso Moreira, ________de______________ de dois mil e vinte e
            ____________.
          </td>
        </tr>

        <tr align="center">
          <td>___________________</td>
          <td>___________________</td>
          <td>___________________</td>
        </tr>
        <tr align="center">
          <td>Secretário (a)</td>
          <td>Diretor (a)</td>
          <td>Supervisor (a)</td>
        </tr>
      </tbody>
    </table>
  </div> -->
</template>

<script>
import { defineComponent, } from "vue";
import { nomenclaturaSituacaoAluno } from '@/utils/nomenclatura';
import axios from "axios";


export default defineComponent({
  components: {},
  props: {
    cabecalho: {},
    dados: "",
    info: {},
  },
  data() {
    return {
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),
      nomenclaturaSituacaoAluno: nomenclaturaSituacaoAluno,
      dataInicio: "",
      dataFim: "",
      height: 0,
    };
  },

  methods: {
    converterDataParaBrasileiro(data) {
      const partes = data.split('-');

      const dataFormatada = partes[2] + '/' + partes[1] + '/' + partes[0];

      return dataFormatada;
    },
  },

  beforeMount() {
    this.dataInicio = this.converterDataParaBrasileiro(this.info.dataInicio);
    this.dataFim = this.converterDataParaBrasileiro(this.info.dataFim);
  },
});
</script>

<style scoped>
/* .responsive-font {
  font-size: 10px;
} */
.borda-externa {
  border: 2px #000 solid;
}
.borda-right {
  border-right: 2px #000 solid;
  /* width: 50%; */
}
.borda-right-1px {
  border-right: 1px solid #000;
}
.borda-bottom {
  border-bottom: 2px #000 solid;
}
.borda-bottom-1px {
  border-bottom: 1px solid #000;
}
.borda-top-1px {
  border-top: 1px #000 solid;
}
.borda-top {
  border-top: 2px #000 solid;
}
.bordas {
  border: 1px solid #000;
}

.title-padding {
  /* padding: 0px 2px 0px 2px; */
}
.tabela {
  max-height: 2px;
  /* margin-bottom: 30px;
  border-collapse: collapse; */
}

.button-generate {
  margin-top: 10px;
  margin-right: 5px;
}
.select {
  appearance: revert;
  width: 100%;
}
.uppercase {
  text-transform: uppercase;
}
.tamanho-titulos-dias {
  width: 3%;
}
.alinhamento {
  margin-top: -15px;
  padding: 14px;
}

.alinhameneto-margem {
  margin-left: -12px;
  margin-right: -12px;
}

.card-filtro {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  margin-top: 15px;
}
</style>
