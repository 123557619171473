import axios from 'axios';

export class Aproveitamento {

  static async buscaAproveitamento(turmaId, alunoId, semestre, nomeAluno, ano, segmentoId, serieId, escolaId, dataInicio, dataFim) {
    // return axios.get(`/aproveitamento-aluno/${turmaId}/${alunoId}/${semestre}/${nomeAluno}/${ano}/${segmentoId}/${serieId}/${escolaId}`)
    return axios.get(`/aproveitamento-aluno?dataInicio=${dataInicio}&dataFim=${dataFim}&anoLetivo=${ano}&turma_id=${turmaId}&nome_aluno=${nomeAluno}&semestre=${semestre}&escola_id=${escolaId}&segmento_id${segmentoId}&serie_id=${serieId}`)
    //http://localhost:8000/api/v1/aproveitamento-aluno?dataInicio=2024-01-01&dataFim=2024-03-10&anoLetivo=2024&turma_id=301&nome_aluno=&semestre=1&escola_id=18&segmento_id=1&serie_id=4&turma_id=174

  }
}
